<template>
  <div class="container">
    <div class="search-area is-flex-wrap-wrap is-justify-content-center">
      <p class="search-header is-one-third">日付指定</p>
      <div class="select is-small">
        <select v-model="start_year">
          <option v-for="year in available_years" :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      年
      <div class="select is-small">
        <select v-model="start_month">
          <option v-for="month in available_months" :key="month">
            {{ month }}
          </option>
        </select>
      </div>
      月
      <div class="select is-small">
        <select v-model="start_day">
          <option v-for="day in available_days" :key="day">
            {{ day }}
          </option>
        </select>
      </div>
      日〜
      <div class="select is-small">
        <select v-model="end_year">
          <option v-for="year in available_years" :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      年
      <div class="select is-small">
        <select v-model="end_month">
          <option v-for="month in available_months" :key="month">
            {{ month }}
          </option>
        </select>
      </div>
      月
      <div class="select is-small">
        <select v-model="end_day">
          <option v-for="day in available_days" :key="day">
            {{ day }}
          </option>
        </select>
      </div>
      日
    </div>

    <search-button @click="getCatalogInquiries" :isLoading="isLoading"></search-button>
    <table class="table is-bordered is-striped">
      <thead>
        <tr>
          <th>日時</th>
          <th>ID</th>
          <th>媒体</th>
          <th>ステータス</th>
          <th>請求カタログ</th>
          <th>送付状況</th>
          <th>残日数</th>
          <th>返送等・否認事項</th>
          <th>成果承認</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="hist in formattedHistory" :key="hist.timestamp">
          <td>{{ hist.datetime }}</td>
          <td>
            <a @click="selectId(hist.client_id)" v-if="hist.status == 'normal'">{{ hist.client_id }}</a>
            <p v-else>{{ hist.client_id }}</p>
          </td>
          <td>{{ hist.media }}</td>
          <td>{{ hist.status }}</td>
          <td>{{ hist.catalogs }}</td>
          <td class="py-1 is-vcentered">
            <p v-if="hist.status == 'error'">---</p>
            <p v-else-if="hist.is_timeover">{{ hist.send_status }}</p>
            <p v-else-if="hist.send_status">完了</p>
            <div v-else class="select is-small">
              <select @change="finishSending($event, hist.builder_id, hist.timestamp)" class="has-text-danger">
                <option value="">ご対応ください!</option>
                <option value="finish">完了</option>
              </select>
            </div>
          </td>
          <td>{{ hist.days_to_go }}</td>
          <td>{{ hist.reject_reason }}</td>
          <td class="py-1 is-vcentered">
            <p v-if="hist.status == 'error'">---</p>
            <p v-else-if="hist.is_approved">承認</p>
            <p v-else-if="hist.is_timeover">承認※</p>
            <button v-else class="button is-small is-primary" @click="approve(hist.builder_id, hist.timestamp)">
              承認
            </button>
          </td>
        </tr>
        <tr v-for="i in 2" :key="i">
          <td v-for="j in 9" :key="j"></td>
        </tr>
      </tbody>
    </table>
    <client-info-modal v-for="hist in history" :key="hist.timestamp" :clientInfo="hist" :clientId="hist.client_id"
      :selectedId="selectedId" @closeModal="closeModal"></client-info-modal>
  </div>
</template>
<script>
import ClientInfoModal from "../components/ClientInfoModal.vue";
import SearchButton from "../components/SearchButton.vue";
import axios from "axios";

export default {
  name: "CatalogInfo",
  components: {
    SearchButton,
    ClientInfoModal,
  },
  data() {
    return {
      available_years: [2021, 2022, 2023, 2024, 2025, 2026],
      available_months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      available_days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      start_year: new Date().getFullYear(),
      start_month: new Date().getMonth() + 1,
      start_day: new Date().getDate(),
      end_year: new Date().getFullYear(),
      end_month: new Date().getMonth() + 1,
      end_day: new Date().getDate(),
      history: [],
      selectedId: "",
      isLoading: false,
    };
  },
  computed: {
    // TODO
    formattedHistory() {
      return this.history.map((x) => {
        if (x.status == "error") {
          return {
            timestamp: x.timestamp,
            datetime: this.timestamp2date(x.timestamp),
            client_id: x.client_id,
            media: this.getMediaName(x.client_id),
            status: x.status,
            builder_id: x.builder_id,
            days_to_go: "---",
            reject_reason: "---",
            send_status: false,
            catalogs: x.inquire_info.catalogs ? x.inquire_info.catalogs.join(",") : "---",
            is_timeover: false
          };
        } else if (this.days_to_go(x.timestamp) <= 0 && x.is_approved) {
          // 残日数が0未満かつ承認済みのとき（正常に承認された場合）
          return {
            timestamp: x.timestamp,
            datetime: this.timestamp2date(x.timestamp),
            client_id: x.client_id,
            media: this.getMediaName(x.client_id),
            status: x.status,
            builder_id: x.builder_id,
            days_to_go: "---",
            reject_reason: "---",
            send_status: x.send_status ? "完了" : "未対応",
            catalogs: x.inquire_info.catalogs ? x.inquire_info.catalogs.join(",") : "---",
            is_approved: true,
            is_timeover: true
          };

        } else if (this.days_to_go(x.timestamp) <= 0 && x.reject_reason == "") {
          // 残日数が0未満かつ否認事由が入っていないときは、自動的に承認とみなす
          return {
            timestamp: x.timestamp,
            datetime: this.timestamp2date(x.timestamp),
            client_id: x.client_id,
            media: this.getMediaName(x.client_id),
            status: x.status,
            builder_id: x.builder_id,
            days_to_go: "---",
            reject_reason: "---",
            send_status: x.send_status ? "完了" : "未対応",
            catalogs: x.inquire_info.catalogs ? x.inquire_info.catalogs.join(",") : "---",
            is_approved: false,
            is_timeover: true
          };
        } else if (this.days_to_go(x.timestamp) <= 0 && x.reject_reason !== "") {
          // 残日数が0未満かつ否認理由が入っている場合（正常に否認した場合）
          return {
            timestamp: x.timestamp,
            datetime: this.timestamp2date(x.timestamp),
            client_id: x.client_id,
            media: this.getMediaName(x.client_id),
            status: x.status,
            builder_id: x.builder_id,
            days_to_go: "---",
            reject_reason: "---",
            send_status: x.send_status ? "完了" : "未対応",
            catalogs: x.inquire_info.catalogs ? x.inquire_info.catalogs.join(",") : "---",
            is_approved: "否認",
            is_timeover: true
          };
        } else {
          return {
            timestamp: x.timestamp,
            datetime: this.timestamp2date(x.timestamp),
            client_id: x.client_id,
            media: this.getMediaName(x.client_id),
            status: x.status,
            builder_id: x.builder_id,
            days_to_go: "残" + this.days_to_go(x.timestamp) + "日",
            reject_reason: x.reject_reason,
            send_status: x.send_status,
            catalogs: x.inquire_info.catalogs.join(","),
            is_approved: x.is_approved,
            is_timeover: false
          }
        }
      });
    },
  },
  methods: {
    getMediaName(inquire_id){
      const media_code = inquire_id.substr(4, 1)
      console.log(media_code)
      if(media_code == "m"){
        return "みんなの工務店リサーチ"
      }else if(media_code=="r"){
        return "ハウスメーカー比較マイスター"
      }else if(media_code=="s"){
        return "マイホーム博士が注文住宅を解説するブログ"
      }else if(media_code=="u"){
        return "注文住宅ヘルプナビ"
      }
    },
    getCatalogInquiries() {
      const start_date = new Date(
        this.start_year,
        this.start_month - 1,
        this.start_day
      );
      const end_date = new Date(
        this.end_year,
        this.end_month - 1,
        this.end_day + 1
      );
      const start_date_timestamp = start_date.getTime() / 1000;
      const end_date_timestamp = end_date.getTime() / 1000;
      this.isLoading = true;

      this.$cognito.getAttributes().then((res) => {
        const sub = res.filter((x) => x.getName() == "sub")[0].getValue();

        axios
          .post(
            "https://hg1ref5qvb.execute-api.ap-northeast-1.amazonaws.com/prd/catalog",
            {
              sub: sub,
              start_timestamp: start_date_timestamp,
              end_timestamp: end_date_timestamp,
            }
          )
          .then((res) => {
            this.history = res.data.result;
            this.isLoading = false;
          })
          .catch(() => {
            alert("ネットワークエラーが発生しました。");
            this.isLoading = false;
          });
      }).catch((err)=>{
        this.isLoading=false
        if(err.message=="User is not authenticated"){
          alert("長時間操作がなかったため接続がタイムアウトしました。再度ログインしてください。")
          this.isLoading = false
          this.$cognito.logout()
          this.$router.push({path: "/login"})
        }
      });
    },
    selectId(clientId) {
      this.selectedId = clientId;
    },
    closeModal() {
      this.selectedId = "";
    },
    timestamp2date(timestamp) {
      const dt = new Date(timestamp * 1000);
      const month =
        dt.getMonth() + 1 < 10 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1;
      const date = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
      const hours = dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours();
      const minutes =
        dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes();
      const seconds =
        dt.getSeconds() < 10 ? "0" + dt.getSeconds() : dt.getSeconds();
      return (
        dt.getFullYear() +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    days_to_go(timestamp) {
      var end_date = new Date(timestamp * 1000);
      end_date.setDate(end_date.getDate() + 27);
      const date_diff = end_date.getTime() - new Date().getTime();
      return Math.round(date_diff / (1000 * 60 * 60 * 24));
    },
    approve(builder_id, timestamp) {
      axios
        .post(
          "https://hg1ref5qvb.execute-api.ap-northeast-1.amazonaws.com/prd/approve",
          {
            sub: builder_id,
            timestamp: timestamp,
          }
        )
        .then(() => {
          this.getCatalogInquiries();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    finishSending(event, builder_id, timestamp) {
      if (event.target.value == "finish") {
        axios
          .post(
            "https://hg1ref5qvb.execute-api.ap-northeast-1.amazonaws.com/prd/send-finished",
            {
              sub: builder_id,
              timestamp: timestamp,
            }
          )
          .then(() => {
            this.getCatalogInquiries();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style scoped>
.search-header {
  background-color: lightgray;
  vertical-align: middle;
  margin-right: 20px;
  margin-left: 20px;
}

.search-area {
  margin: auto;
  display: flex;
  vertical-align: middle;
  border: solid;
  border-width: 1px;
}

table {
  width: 100%;
}

tr {
  height: 41px;
}
</style>