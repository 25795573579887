<template>
    <button class="button is-primary" :class="{ 'is-loading': isLoading}">
        <span class="icon-text">
            <span class="icon">
            </span>
            この条件で表示する
            </span>
    </button>
</template>
<script>
export default {
    name: "SearchButton",
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
button {
    margin-top: 20px;
    margin-bottom: 20px;
}

</style>