<template>
  <div>
    <nav class="navbar">
      <div class="navbar-brand">
        <img src="@/assets/brand_logo.png" class="brand_logo" />
      </div>
      <div class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item">
            <div>
              <p>
                <a @click="selectedPage = 'catalogUpload'">{{ builderInfo["custom:company"] }}</a>
              </p>
              <p>{{ builderInfo.name }} 様</p>
            </div>
          </div>
          <div class="navbar-item">
            <button type="button" class="button is-light" @click="logout()">ログアウト</button>
          </div>
        </div>
        <hr class="navbar-divider">
      </div>
    </nav>
    <div class="tabArea">
      <div class="columns">
        <div class="column"></div>
        <button class="column button is-medium is-fullwidth" :class="{ 'is-info': selectedPage === 'accessInfo' }"
          @click="selectedPage = 'accessInfo'">
          アクセス状況
        </button>
        <button class="column button is-medium is-fullwidth" :class="{ 'is-info': selectedPage === 'catalogInfo' }"
          @click="selectedPage = 'catalogInfo'">
          カタログ請求状況
        </button>
        <div class="column"></div>
      </div>
    </div>
  </div>
  <main>
    <access-info v-show="selectedPage == 'accessInfo'"></access-info>
    <catalog-info v-show="selectedPage == 'catalogInfo'"></catalog-info>
    <catalog-upload-view v-show="selectedPage == 'catalogUpload'" />
  </main>
</template>
<script>
import AccessInfo from "./AccessInfo.vue";
import CatalogInfo from "./CatalogInfo.vue";
import CatalogUploadView from "./CatalogUploadView.vue"
export default {
  name: "MainPage",
  components: {
    AccessInfo,
    CatalogInfo,
    CatalogUploadView
  },
  data() {
    return {
      selectedPage: "accessInfo",
      builderName: "",
      builderInfo: {}
    };
  },
  mounted() {
    this.$cognito.getAttributes()
      .then(result => {
        for (var v of result) {
          this.builderInfo[v.getName()] = v.getValue()
        }
      })
  },
  methods: {
    logout() {
      this.$cognito.logout()
      this.$router.push({ "path": "/login" })
    }
  }
};
</script>
<style scoped>
img {
  width: 60%;
}

nav {
  border: solid;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-width: 2px;
}

.tabArea {
  margin-top: 40px;
}

main {
  margin-top: 80px;
}

.brand_logo {
  height: 80px;
  width: auto;
}
</style>