<template>
  <div class="container">
    <div class="search-area is-flex-wrap-wrap is-justify-content-center">
      <p class="search-header is-one-third">
        媒体選択
      </p>
      <div class="select is-small">
        <select v-model="selectedMedia">
          <option v-for="media in availableMedia" :key="media">
            {{media}}
          </option>
        </select>
      </div>
      <p class="search-header is-one-third">
        月指定
      </p>
      <div class="select is-small">
        <select v-model="start_year">
          <option v-for="year in available_years" :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      年
      <div class="select is-small">
        <select v-model="start_month">
          <option v-for="month in available_months" :key="month">
            {{ month }}
          </option>
        </select>
      </div>
      月〜
      <div class="select is-small">
        <select v-model="end_year">
          <option v-for="year in available_years" :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      年
      <div class="select is-small">
        <select v-model="end_month">
          <option v-for="month in available_months" :key="month">
            {{ month }}
          </option>
        </select>
      </div>
      月
    </div>

    <search-button @click="getAccessInfo()" :isLoading="isLoading"></search-button>
    <div class="columns">
      <div class="column is-two-thirds">
        <table class="table is-bordered"></table>
      </div>
    </div>
    <div class="table-container container">
      <table class="table is-bordered is-striped">
        <thead>
          <tr>
            <th>日時</th>
            <th>読者数</th>
            <th>クリック数</th>
            <th>クリック率</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="hist in history" :key="hist.month">
            <td>{{hist.month}}</td>
            <td>{{hist.access_count}}</td>
            <td>{{hist.click_count}}</td>
            <td>{{click_ratio(hist.click_ratio)}}</td>
          </tr>
          <tr v-for="i in 2" :key="i">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import SearchButton from "../components/SearchButton.vue";
import axios from "axios";
export default {
  name: "AccessInfo",
  components: {
    SearchButton,
  },
  data() {
    return {
      isLoading: false,
      available_years: [2021, 2022, 2023, 2024, 2025, 2026],
      available_months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      start_year: new Date().getFullYear(),
      start_month: new Date().getMonth() + 1,
      end_year: new Date().getFullYear(),
      end_month: new Date().getMonth() + 1,
      selectedMedia: "",
      varesearch: 0,
      meister: 0,
      santodomingo: 0,
      uclid:0,
      origin: "www.varesearch.com",
      history: [],
      sub: ""
    };
  },
  computed: {
    availableMedia(){
      let media = []
      if(this.varesearch==1) media.push("みんなの工務店リサーチ")
      if(this.meister==1) media.push("ハウスメーカー比較マイスター")
      if(this.santodomingo==1) media.push("マイホーム博士が注文住宅を解説するブログ")
      if(this.uclid==1) media.push("注文住宅ヘルプナビ")
      return media
    }
  },
  mounted(){
    this.$cognito.getAttributes().then((res)=> {
      this.sub = res.filter((x)=>x.getName() == "sub")[0].getValue()
      this.varesearch = res.filter((x) => x.getName() == "custom:varesearch")[0].getValue()
      this.meister = res.filter((x) => x.getName() == "custom:meister")[0].getValue()
      this.santodomingo = res.filter((x) => x.getName() == "custom:santodomingo")[0].getValue()
      this.uclid = res.filter((x) => x.getName() == "custom:uclid")[0].getValue()
    })
  },
  methods: {
    click_ratio(raw_click_ratio){
      return String(Math.round(raw_click_ratio*100)/100) + "%"
    },
    validateDate() {
      const startDate = new Date(this.start_year, this.start_month, 1)
      const endDate = new Date(this.end_year, this.end_month, 1)
      return startDate <= endDate
    },
    getAccessInfo() {
      this.isLoading = true
      if(this.selectedMedia==""){
        alert("媒体を指定してください")
        this.isLoading = false
        return
      }else{
        if(this.selectedMedia=="みんなの工務店リサーチ") this.origin = "www.varesearch.com"
        if(this.selectedMedia=="ハウスメーカー比較マイスター") this.origin = "reform-meister.jp"
        if(this.selectedMedia=="マイホーム博士が注文住宅を解説するブログ") this.origin = "www.santodomingocc.com"
        if(this.selectedMedia=="注文住宅ヘルプナビ") this.origin = "www.uclid.org"
      }
      if(!this.validateDate()){
        alert("正しい期間を指定してください")
        this.isLoading = false
        return
      }
      console.log(this.origin)
      this.$cognito.getAttributes().then((res) => {
        const sub = res.filter((x) => x.getName() == "sub")[0].getValue();

        axios
          .post(
            "https://hg1ref5qvb.execute-api.ap-northeast-1.amazonaws.com/prd/access-info",
              {
                sub: sub,
                start_date: new Date(this.start_year, this.start_month-1, 1, 9, 0).toISOString(),
                end_date: new Date(this.end_year, this.end_month, 0, 9, 0).toISOString(),
                origin: this.origin
              }
          )
          .then((res) => {
            this.history = res.data
          })
          .catch(() => {
            alert("ネットワークエラーが発生しました。");
          })
          .finally(()=>{
            this.isLoading = false;
          });

      }).catch((err)=>{
        if(err.message=="User is not authenticated"){
          alert("長時間操作がなかったため接続がタイムアウトしました。再度ログインしてください。")
          this.isLoading = false
          this.$cognito.logout()
          this.$router.push({path: "/login"})
        }
      })
    }
  },
};
</script>
<style scoped>
p {
  vertical-align: bottom;
}

.search-header {
  background-color: lightgray;
  vertical-align: middle;
  margin-right: 20px;
  margin-left: 20px;
}

.search-area {
  margin: auto;
  display: flex;
  vertical-align: middle;
  border: solid;
  border-width: 1px;
}

table {
  width: 100%;
}

tr {
  height: 41px;
}
</style>