<template>
  <div class="modal" :class="{ 'is-active': isSelected }">
    <div class="modal-background" @click="closeThis"></div>
    <div class="modal-content">
      <div class="box">
        <div :id="clientId">
          <div class="is-flex is-justify-content-flex-end">
            <button class="" @click="print()">このページを印刷</button>
          </div>
          <p class="mb-4">請求者個人情報</p>
          <div class="personal-info">
            <p>氏名: {{ fullName }} ({{ fullNameKana }})</p>
            <br />
            <p>現住所: </p>
            <p>〒 {{ postalCode }}</p>
            <p>{{ fullAddress }}</p>
            <br />
            <p>TEL: {{ phoneNumber }}</p>
            <p>メール: {{ email }}</p>
            <br />
            <p>その他請求者情報</p>
            <p>年齢: {{ generation }}</p>
            <p>世帯年収: {{ annualIncome }}</p>
          </div>
          <p class="my-4">検討状況・問い合わせ内容詳細</p>
          <div class="personal-info mb-2">
            <p>建築予定地: {{ expectedPrefecture }}</p>
            <p>建築予定時期: {{ expectedSchedule }}</p>
            <br />
            <p>土地の有無: {{ hasOwnLand }}</p>
            <p>現在の検討状況: {{ currentStatus }}</p>
            <p>建築予算: {{ budget }}</p>
            <p>希望する家の広さ: {{ space }}</p>
            <br />
            <p>問い合わせ内容:</p>
            <p v-for="inquire in inquiries" :key="inquire">・{{ inquire }}</p>
            <br />
            <p>問い合わせフリーテキスト:</p>
            <p> {{ inquiryText }}</p>
            <br />
          </div>
          <!-- テキストリンク版 -->
        </div>
        <a @click="openRejectField" class="has-text-danger">このお客様の成果を否認する</a>
        <transition name="toggleReason">
          <div v-if="isOpenRejectField" class="my-2">
            <p class="my-4">否認事由</p>
            <div class="columns">
              <div class="column">
                <input class="input" type="text" placeholder="宛先不明/返送 等" v-model="rejectReason">
              </div>
              <div class="column">
                <button id="reject-button" class="button is-danger" @click="setRejectReason">否認</button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <button type="button" class="modal-close is-large" aria-label="close" @click="closeThis"></button>
  </div>
</template>
<script>
import axios from "axios"

export default {
  name: "ClientInfoModal",
  props: {
    selectedId: {
      type: String,
    },
    clientInfo: {
      type: Object,
    },
    clientId: {
      type: String,
    },
  },
  data() {
    return {
      isSelected: false,
      isOpenRejectField: false,
      rejectReason: "",
    };
  },
  computed: {
    fullName() {
      return this.clientInfo.inquire_info.sei + " " + this.clientInfo.inquire_info.mei
    },
    fullNameKana() {
      return this.clientInfo.inquire_info.sei_kana + " " + this.clientInfo.inquire_info.mei_kana
    },
    postalCode() {
      return this.clientInfo.inquire_info.postal_code.slice(0, 3) + "-" + this.clientInfo.inquire_info.postal_code.slice(3)
    },
    fullAddress() {
      return this.clientInfo.inquire_info.prefecture + " " + this.clientInfo.inquire_info.city_name + " " + this.clientInfo.inquire_info.street_name + " " + this.clientInfo.inquire_info.building_name
    },
    phoneNumber() {
      return this.clientInfo.inquire_info.phone_number
    },
    email() {
      return this.clientInfo.inquire_info.email_address
    },
    generation() {
      return this.clientInfo.inquire_info.generation == "" ? "未入力" : this.clientInfo.inquire_info.generation + "歳"
    },
    annualIncome() {
      return this.clientInfo.inquire_info.annual_income == "" ? "未入力" : this.clientInfo.inquire_info.annual_income + "万円"
    },
    expectedPrefecture() {
      return this.clientInfo.inquire_info.expected_prefecture
    },
    expectedSchedule() {
      return this.clientInfo.inquire_info.expected_schedule
    },
    hasOwnLand() {
      return this.clientInfo.inquire_info.has_own_land
    },
    currentStatus() {
      return this.clientInfo.inquire_info.current_status
    },
    budget() {
      return this.clientInfo.inquire_info.budget
    },
    space() {
      return this.clientInfo.inquire_info.space
    },
    inquiries() {
      return this.clientInfo.inquire_info.inquiries
    },
    inquiryText() {
      return this.clientInfo.inquire_info.inquiry_text != "" ? this.clientInfo.inquire_info.inquiry_text : "未入力"
    }
  },
  updated() {
    this.isSelected = this.clientId === this.selectedId;
  },
  methods: {
    closeThis() {
      this.isOpenRejectField = false
      this.$emit("closeModal");
    },
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById(this.clientId).innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      console.log(prtHtml)
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    openRejectField() {
      this.isOpenRejectField = true
      setTimeout(() => {
        const rejectButton = document.getElementById("reject-button")
        rejectButton.scrollIntoView()
      }, 200)
    },
    setRejectReason() {
      if (this.rejectReason == "") {
        alert("否認事由を入力してください。")
        return
      }
      axios
        .post(
          "https://hg1ref5qvb.execute-api.ap-northeast-1.amazonaws.com/prd/set-reject-reason",
          {
            sub: this.clientInfo.builder_id,
            timestamp: this.clientInfo.timestamp,
            reject_reason: this.rejectReason
          }
        )
        .then(() => {
          alert("否認事由を設定しました。")
        })
        .catch((err) => {
          console.log(err);
        });


    }
  },
};
</script>
<style scoped>
.personal-info {
  text-align: left;
}

.personal-info p {
  font-size: 18px;
  margin: 2em, 0;
}

@media print {

  html,
  body {
    height: auto;
  }
}

.toggleReason-enter-active {
  transition: opacity .3s;
}
</style>
